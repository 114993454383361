import { useCallback, useEffect, useState } from "react";
import { RiImageAddLine } from "react-icons/ri";
import { FaCommentAlt } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa6";
import {
  FindFriendResult,
  SearchTagsResult,
  findFriend,
  searchTags,
} from "../utils/post";
import Header from "../component/Header";
import uploadPost from "../utils/upload";
import { toast } from "react-toastify";
import MediaComponent from "../component/MediaComponent";

export type formPostUploader = {
  postName: string;
  videos?: File[];
  images?: File[];
  tags: any[];
  taggedUser: any[];
  finalSubmitted: Boolean;
  postType: string;
  postPrivacy: string;
  allowComment: Boolean;
};

const PostUploaderForm = () => {
  const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([]);
  const [selectedVidoFiles, setSelectedVideoFiles] = useState<File[]>([]);
  const { createPost, progress } = uploadPost();
  const [acceptedFiles, setAcceptedFiles] =
    useState<string>("image/*, video/*");

  const [friendSearch, setFriendSearch] = useState<FindFriendResult[]>([]);
  const [tagSearchResults, setTagSearchResults] = useState<SearchTagsResult[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const [tagSelected, setTagSelected] = useState<
    { tagName: string; _id: string }[]
  >([]);

  const [friendSelected, setFriendSelected] = useState<FindFriendResult[]>([]);
  const [tagQuery, setTagQuery] = useState<string>("");
  const [friendQuery, setFriendQuery] = useState<string>("");

  const form = useForm<formPostUploader>();
  const { register, handleSubmit, formState, reset } = form;
  const { errors } = formState;

  const handleAddNewTag = (tagName: string) => {
    setTagSelected([...tagSelected, { tagName, _id: tagName }]);
    setTagQuery("");
  };

  useEffect(() => {
    (async () => {
      if (tagQuery.trim()) {
        try {
          const response = await searchTags({
            _id: tagQuery,
            search: tagQuery,
          });
          if (response.status === 200) {
            setTagSearchResults(response.data.results);
          } else {
            console.error("Unexpected response status:", response.status);
          }
        } catch (error) {
          console.error("Error during search:", error);
        }
      }
    })();
  }, [tagQuery]);

  useEffect(() => {
    (async () => {
      if (friendQuery.trim()) {
        try {
          const response = await findFriend({
            fullname: friendQuery,
            search: friendQuery,
          });
          if (response.status === 200) {
            setFriendSearch(response.data.results);
          } else {
            console.error("Unexpected response status:", response.status);
          }
        } catch (error) {
          console.error("Error during search:", error);
        }
      }
    })();
  }, [friendQuery]);

  const onsubmit = async (data: formPostUploader) => {
    try {
      const formData: FormData = new FormData();
      formData.append("postName", data.postName);
      formData.append("postPrivacy", data.postPrivacy);
      formData.append("allowComment", data.allowComment.toString());
      formData.append("finalSubmitted", data.allowComment.toString());
      formData.append("postType", "post");

      if (tagSelected.length > 0) {
        formData.append("tags", JSON.stringify(tagSelected));
      }

      if (friendSelected.length > 0) {
        const taggedUsers = friendSelected.map((friend) => friend.userHandle);
        formData.append("taggedUser", JSON.stringify(taggedUsers));
      }
      if (selectedImageFiles && selectedImageFiles.length > 0) {
        const imagesArray = Array.from(selectedImageFiles);
        imagesArray.forEach((image) => {
          formData.append("images", image);
        });
      }
      if (selectedVidoFiles && selectedVidoFiles.length > 0) {
        const videosArray = Array.from(selectedVidoFiles);
        videosArray.forEach((video) => {
          formData.append("videos", video);
        });
      }
      setLoading(true);

      await createPost(formData);
      setLoading(false);
      reset();
      setSelectedImageFiles([]);
      setSelectedVideoFiles([]);
      setTagSelected([]);
      setFriendSelected([]);
      setAcceptedFiles("image/*, video/*");
      setTagQuery("");
      setFriendQuery("");
      toast.success("Post created successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const handleFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (files) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].type.split("/")[0] === "image") {
            setAcceptedFiles("image/*");
          } else if (files[i].type.split("/")[0] === "video") {
            setAcceptedFiles("video/*");
          }
        }

        const newImages: File[] = [];
        const newVideos: File[] = [];

        for (let i = 0; i < files.length; i++) {
          if (files[i].type.split("/")[0] === "image") {
            newImages.push(files[i]);
          } else if (files[i].type.split("/")[0] === "video") {
            newVideos.push(files[i]);
          }
        }
        setSelectedImageFiles((prevImages) => [...prevImages, ...newImages]);
        setSelectedImageFiles((prevImages) => prevImages.slice(0, 5));
        setSelectedVideoFiles((prevVideos) => [...prevVideos, ...newVideos]);
      }
    },
    []
  );

  const handlePreviewImagesDelete = useCallback(
    (index: number) => {
      const datas = [...selectedImageFiles];
      datas.splice(index, 1);
      if (datas.length === 0) {
        setAcceptedFiles("image/*, video/*");
      }
      setSelectedImageFiles(datas);
    },
    [selectedImageFiles]
  );

  const handlePreviewVideoDelete = useCallback(
    (index: number) => {
      const datas = [...selectedVidoFiles];
      datas.splice(index, 1);
      if (datas.length === 0) {
        setAcceptedFiles("image/*, video/*");
      }
      setSelectedVideoFiles(datas);
    },
    [selectedVidoFiles]
  );

  return (
    <main>
      <Header />
      <section className="post-uploader-form flex flex-col justify-center items-center h-auto py-20 lg:px-0 px-4 ">
        <form
          className="p-6 lg:w-[900px] lg:h-auto h-[500px] lg:overflow-hidden overflow-y-scroll w-full shadow-lg rounded-md "
          onSubmit={handleSubmit(onsubmit)}
        >
          <div className="mb-10 flex items-center justify-center">
            <img src={"./logo.png"} alt="" className="lg:w-64 w-auto" />
          </div>
          <div className="flex flex-col">
            <label htmlFor="description" className="text-sm text-gray-600">
              Post Name <span className="text-red-500">*</span>
            </label>
            <textarea
              cols={5}
              className="resize-none rounded-md h-36 border-2 border-gray-300 p-4 outline-none"
              {...register("postName", { required: "Post Name is required" })}
            ></textarea>
            <p className="text-red-500">{errors.postName?.message}</p>
          </div>
          <div className="grid lg:grid-cols-1 grid-cols-1 gap-4 items-center mt-7">
            <div className="flex flex-col w-full">
              <label htmlFor="tags" className="text-sm text-gray-600">
                Tags:
              </label>
              <input
                type="text"
                autoComplete="off"
                className="border-2 w-full py-3 px-3 outline-none rounded-md"
                value={tagQuery}
                onChange={(e) => setTagQuery(e.target.value)}
              />
              {tagQuery !== "" && tagSearchResults.length > 0 && (
                <div className="w-[853px] bg-white shadow-md rounded-md mt-20 border-[1px] p-3 absolute">
                  <ul className="w-full flex flex-wrap gap-5 px-4">
                    {tagSearchResults.map((tag, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setTagSelected((prevTags) => [
                            ...prevTags,
                            { tagName: tag._id!, _id: tag._id },
                          ]);
                          setTagQuery("");
                        }}
                        className="flex items-center gap-2 cursor-pointer bg-gray-300 px-7 rounded-md py-1"
                      >
                        <span className="">{tag._id!}</span>
                        <span className="bg-white shadow-lg px-1 rounded-sm mt-1 text-[10px]">
                          {tag.count}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {tagQuery !== "" && tagSearchResults.length === 0 && (
                <button
                  onClick={() => handleAddNewTag(tagQuery)}
                  className="bg-indigo-500 text-white font-bold px-4 py-2 rounded-md mt-2 flex items-center justify-center gap-1"
                >
                  <FaPlus size={21} /> Add New Tag
                </button>
              )}
              <div className="flex flex-wrap gap-2 mt-2">
                {tagSelected.map((tag, index) => (
                  <div
                    key={index}
                    className="bg-gray-200 shadow-lg text-black rounded-md px-2 py-1 flex items-center gap-2"
                  >
                    {tag.tagName}
                    <RxCross2
                      className="cursor-pointer"
                      onClick={() => {
                        setTagSelected((prevTags) =>
                          prevTags.filter((_, i) => i !== index)
                        );
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-col w-full">
              <label htmlFor="friends" className="text-sm text-gray-600">
                Friends:
              </label>
              <input
                type="text"
                autoComplete="off"
                className="border-2 w-full py-3 px-3 outline-none rounded-md"
                value={friendQuery}
                onChange={(e) => setFriendQuery(e.target.value)}
              />
              {friendQuery !== "" && friendSearch.length > 0 && (
                <div className="w-[853px] bg-white shadow-md rounded-md mt-20 border-[1px] p-3 absolute">
                  <ul className="w-full flex flex-wrap gap-5 px-4">
                    {friendSearch.map((friend, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setFriendSelected((prevFriends) => [
                            ...prevFriends,
                            friend,
                          ]);
                          setFriendQuery("");
                        }}
                        className="flex items-center gap-2 cursor-pointer bg-gray-300 px-7 rounded-md py-1"
                      >
                        {friend.fullname}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="flex flex-wrap gap-2 mt-2">
                {friendSelected.map((friend, index) => (
                  <div
                    key={index}
                    className="bg-gray-200 shadow-lg text-black  rounded-md px-2 py-1 flex items-center gap-2"
                  >
                    {friend.fullname}
                    <RxCross2
                      className="cursor-pointer"
                      onClick={() => {
                        setFriendSelected((prevFriends) =>
                          prevFriends.filter((_, i) => i !== index)
                        );
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col w-full">
              <label htmlFor="tagsFrinds" className="text-sm text-gray-600">
                Privacy:
              </label>
              <select
                className="border-2 border-gray-300 outline-none p-3 rounded-md"
                {...register("postPrivacy")}
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
              </select>
            </div>
          </div>

          <div className="mt-7">
            <ul className="flex mb-5 space-x-2">
              {selectedVidoFiles.length === 0 && (
                <li
                  onClick={() => setAcceptedFiles("image/*")}
                  className={`${
                    acceptedFiles === "image/*" && "bg-blue-500 text-white"
                  } px-2 py-1 rounded-md shadow-md cursor-pointer`}
                >
                  Images ({5 - selectedImageFiles.length})
                </li>
              )}
              {selectedImageFiles.length === 0 && (
                <li
                  onClick={() => setAcceptedFiles("video/*")}
                  className={`${
                    acceptedFiles === "video/*" && "bg-blue-500 text-white"
                  } px-2 py-1 rounded-md shadow-md cursor-pointer`}
                >
                  Video
                </li>
              )}
            </ul>
            {selectedVidoFiles.length === 0 &&
              selectedImageFiles.length < 5 && (
                <label htmlFor="imageFile">
                  <div className="w-full h-24 border border-dashed border-gray-500 flex justify-center items-center">
                    <RiImageAddLine size={35} />
                    <input
                      type="file"
                      id="imageFile"
                      className="hidden"
                      onChange={handleFile}
                      multiple={acceptedFiles === "image/*" ? true : false}
                      accept={acceptedFiles}
                    />
                  </div>
                </label>
              )}

            <MediaComponent
              handlePreviewImagesDelete={handlePreviewImagesDelete}
              handlePreviewVideoDelete={handlePreviewVideoDelete}
              selectedImageFiles={selectedImageFiles}
              selectedVidoFiles={selectedVidoFiles}
            />
          </div>

          <div className="flex items-center gap-2 mt-3">
            <label
              htmlFor="comment"
              className="flex items-center text-sm text-gray-600 gap-1"
            >
              <FaCommentAlt />
              Allow Comments
            </label>
            <input
              type="checkbox"
              id="comment"
              {...register("allowComment")}
              defaultChecked={true}
            />
          </div>
          <div className="flex justify-end">
            <button className="bg-indigo-500 text-white font-bold py-2 px-8 rounded-md">
              {/* Post */}
              {loading === true
                ? progress === 100
                  ? "Optimizing..."
                  : "Uploading..."
                : "Post"}
            </button>
          </div>
          {progress > 0 && (
            <div>
              <strong>{progress.toFixed(2)}%</strong>
              <div
                className="bg-blue-600 h-1.5 rounded-full dark:bg-blue-500"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          )}
        </form>
      </section>
    </main>
  );
};

export default PostUploaderForm;
