import React, { useCallback, useMemo } from "react";
import { RxCross2 } from "react-icons/rx";

type MediaComponentProps = {
    selectedImageFiles: File[];
    selectedVidoFiles: File[];
    handlePreviewImagesDelete: (index: number) => void;
    handlePreviewVideoDelete: (index: number) => void;
}
const MediaComponent = ({ selectedImageFiles, selectedVidoFiles, handlePreviewImagesDelete, handlePreviewVideoDelete }: MediaComponentProps) => {
    const memoizedHandlePreviewImagesDelete = useCallback((index: number) => {
        handlePreviewImagesDelete(index);
    }, [handlePreviewImagesDelete]);

    const memoizedHandlePreviewVideoDelete = useCallback((index: number) => {
        handlePreviewVideoDelete(index);
    }, [handlePreviewVideoDelete]);

    const renderDeleteButton = (index: number, isImage = true) => (
        <div
            className="absolute top-[-5px] p-1 rounded-full cursor-pointer bg-red-500 right-[-3px]"
            onClick={() => (isImage ? memoizedHandlePreviewImagesDelete(index) : memoizedHandlePreviewVideoDelete(index))}
        >
            <RxCross2 size={15} color="white" />
        </div>
    );

    const renderMedia = (ele: any, index: number, isImage = true) => (
        <div className="relative" key={isImage ? `image-${index}` : `video-${index}`}>
            <div className="Image_Preview w-32 border-[1px] border-gray-400 rounded-md h-32 flex justify-center items-center overflow-hidden">
                {isImage ? (
                    <img
                        src={URL.createObjectURL(ele)}
                        className="w-full h-full object-cover"
                        alt=""
                    />
                ) : (
                    <video
                        src={URL.createObjectURL(ele)}
                        className="w-full"
                        autoPlay
                        muted
                    />
                )}
                {renderDeleteButton(index, isImage)}
            </div>
        </div>
    );

    return useMemo(() => (
        <div className="flex flex-wrap mt-4 gap-3">
            {selectedImageFiles.map((ele: any, index: number) => renderMedia(ele, index, true))}
            {selectedVidoFiles.map((ele: any, index: number) => renderMedia(ele, index, false))}
        </div>
    ), [selectedImageFiles, selectedVidoFiles, memoizedHandlePreviewImagesDelete, memoizedHandlePreviewVideoDelete]);
};

export default MediaComponent;
