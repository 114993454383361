import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Results } from "../utils/user";

const Header = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<Results>();
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("token")!));
  }, []);
  const logoutUser = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <header>
    
      <div onClick={() => logoutUser()} className="flex justify-end px-10 py-5 items-center gap-2">
      {user &&  'Welcome, ' + user?.user.fullname}
        <button className="bg-indigo-500 text-white font-bold px-4 py-2 rounded-md">
          Logout
        </button>
      </div>
    </header>
  );
};

export default Header;
