import axios from "axios";
import { baseUrl, postUrl } from ".";


// Search Tags
export interface TagRoot {
  status: number;
  data: {
    message: string;
    results: SearchTagsResult[];
  };
}

export interface SearchTagsResult {
  _id: string;
  count?: number;
  search: string;
}

export const searchTags = async (data: SearchTagsResult): Promise<TagRoot> => {
  try {
    const res = await axios.post(baseUrl + "tags/searchTags", data);
    return res as TagRoot;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as TagRoot;
  }
};

// Search Friend
export interface FriendRoot {
  status: number;
  data: {
    message: string;
    results: FindFriendResult[];
  };
}

export interface FindFriendResult {
  fullname?: string;
  profileFile?: string;
  userHandle?: string;
  followStatus?: boolean;
  search?: string;
}

export const findFriend = async (
  data: FindFriendResult
): Promise<FriendRoot> => {
  try {
    const res = await axios.post(`${baseUrl}user/findFriend`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")!).token}`,
      },
    });

    return res as FriendRoot;
  } catch (error: any) {
    console.error("Error in findFriend:", error);
    return error.response.data as FriendRoot;
  }
};

// Post Create

export interface RootPostAdd {
  status: number;
  data: RootSinglePost;
}

export interface RootSinglePost {
  massage: string;
  results: ResultPost;
}

export interface ResultPost {
  postName: string;
  videos: Video[];
  images: string;
  tags: any[];
  taggedUser: any[];
  finalSubmitted: Boolean;
  postType: string;
  postPrivacy: string;
  allowComment: Boolean;
  _id: string;
}
export interface Video {
  url: string;
  _id: string;
  title: string;
  duration: string;
  level: string;
}

export const createPost = async (data: FormData): Promise<RootPostAdd> => {
  try {
    const res = await axios.post(`${postUrl}post/create`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")!).token}`,
      },
      onUploadProgress: (progressEvent: any) => {
        const percentageCompleted =
          (progressEvent.loaded / progressEvent.total) * 100;
        console.log("Percentage Completed", percentageCompleted);
      
      },
    });
    return res as RootPostAdd;
  } catch (error: any) {
    console.log("Error", error);
    return error.response as RootPostAdd;
  }
};
