// useStore.ts
import axios from "axios";
import { create } from "zustand";
import { baseUrl, postUrl } from ".";

interface RootPostAdd {
  // Define the properties based on your API response
}

interface State {
  posts: RootPostAdd[];
  progressLoading: boolean;
  error: string | null;
  progress: number;
  createPost: (data: FormData) => Promise<void>;
}

const uploadPost = create<State>((set) => ({
  posts: [],
  progressLoading: false,
  error: null,
  progress: 0,
  createPost: async (data: FormData) => {
    set({ progressLoading: true, error: null });
    try {
      const res = await axios.post<RootPostAdd>(`${postUrl}post/create`, data, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("token")!).token
          }`,
        },
        onUploadProgress: (progressEvent: any) => {
          const percentageCompleted =
            (progressEvent.loaded / progressEvent.total) * 100;

          set({ progress: percentageCompleted });
        },
      });
      set((state) => ({
        posts: [...state.posts, res.data],
        progressLoading: false,
        progress: 0,
      }));
    } catch (error: any) {
      console.error("Error", error);
      set({
        error: error.response?.data?.message || "An error occurred",
        progressLoading: false,
      });
    }
  },
}));

export default uploadPost;
