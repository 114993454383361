// import { Button } from "@material-tailwind/react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { userLogin } from "../../utils/user";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

type FormValue = {
  email: string;
  password: string;
};
const LoginForm = () => {
  const form = useForm<FormValue>({ mode: "onChange" });
  const [loading, setLoading] = useState(false);
  const { register, control, handleSubmit, formState } = form;
  const { errors } = formState;
  const router = useNavigate();
  const onsubmit = async (data: FormValue) => {
    setLoading(true);

    const response = await userLogin(data);

    if (response.status === 200) {
      const token = response.data.results;
      localStorage.setItem("token", JSON.stringify(token));
      toast.success(response.data.message);
      router("/");
    } else if (response.status == 443) {
      router(`/otp?email=${data.email}`);
      toast.error(`Error ${response.data.message}`);
    } else {
      toast.error(response.data.message);
    }
    setLoading(false);
  };
  const [passwordEye, setPasswordEye] = useState(false);
  const handelPassword = () => {
    setPasswordEye(!passwordEye);
  };
  const [isFocused, setIsFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);

  const [pageloading, setPageLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      router("/");
    }
    setPageLoading(false);
  }, [pageloading]);

  return (
    <>
      {pageloading && <div>Loading</div>}
      {!pageloading && (
        <section className="flex flex-col justify-center items-center w-full mt-16">
          <img src={"./logo.png"} alt="logo" className="w-56 object-contain" />
          <div className="w-full flex justify-center items-center flex-col">
            <form
              className="py-5 lg:w-[700px] md:w-full sm:w-full w-full lg:px-0 md:px-5 sm:px-5 px-5  flex flex-col justify-center items-center"
              onSubmit={handleSubmit(onsubmit)}
            >
              <div className="mt-6 w-full pt-0 ">
                <div
                  className={`rounded-md custom-input border-2 border-gray-300 ${
                    errors.email
                      ? "focus:border-red-500 focus:ring-red-500 border-red-500 border-2"
                      : emailFocused
                      ? "focus:border-indigo-500 focus:ring-indigo-500 border-indigo-500 border-2"
                      : ""
                  }`}
                  onFocus={() => setEmailFocused(true)}
                  onBlur={() => setEmailFocused(false)}
                >
                  <input
                    placeholder="Email"
                    id="email"
                    type="text"
                    color="indigo"
                    className="rounded-md py-5 px-4 outline-none w-full font-mulish "
                    autoFocus={false}
                    {...register("email", {
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid Email Address",
                      },
                      required: "Email is required*",
                    })}
                  />
                </div>
                <p className="text-red-900  mb-[-35px] font-mulish">
                  {errors.email?.message}
                </p>
              </div>

              <div className="mt-7 w-full ">
                <div
                  className={`relative bg-blue-gray-200 rounded-md mt-10 custom-input border-2 border-gray-300 ${
                    errors.password
                      ? "focus:border-red-500 focus:ring-red-500 border-red-500 border-2"
                      : isFocused
                      ? "focus:border-indigo-500 focus:ring-indigo-500 border-indigo-500 border-2"
                      : ""
                  }`}
                  onBlur={() => setIsFocused(false)}
                  onFocus={() => setIsFocused(true)}
                >
                  <input
                    type={passwordEye === false ? "password" : "text"}
                    id="password"
                    color="indigo"
                    placeholder="Password"
                    className="rounded-md py-5 outline-none w-full  px-4 font-mulish"
                    {...register("password", {
                      required: "Password is required*",

                      minLength: {
                        value: 8,
                        message: "Minimum Password length is 8",
                      },
                      maxLength: {
                        value: 20,
                        message: "Maximum Password length is 20",
                      },
                    })}
                  />
                  <div className="absolute top-5 right-5">
                    {passwordEye === false ? (
                      <IoEye
                        size={25}
                        onClick={handelPassword}
                        className="text-[#757575]"
                      />
                    ) : (
                      <IoEyeOff
                        size={25}
                        onClick={handelPassword}
                        className="text-[#757575]"
                      />
                    )}
                  </div>
                </div>
                <p className="text-red-900 mb-[-15px] font-mulish">
                  {errors.password?.message}
                </p>
              </div>

              <div className="w-full items-center flex justify-center mt-11">
                {!loading && (
                  <button
                    type="submit"
                    className="bg-login  lg:w-[720px] md:w-full sm:w-full w-full py-4 rounded-md tracking-wider text-[15px] outline-none bg-indigo-700 text-white font-bold"
                  >
                    Login
                  </button>
                )}

                {loading && (
                  <div className="bg-login  w-full flex justify-center py-4 rounded-md tracking-wider text-[15px] outline-none bg-indigo-700 text-white font-bold">
                    loading...
                  </div>
                )}
              </div>
            </form>
          </div>
        </section>
      )}
    </>
  );
};

export default LoginForm;
